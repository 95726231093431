import React, {useEffect, useState} from "react";
import {graphql, Link, navigate} from "gatsby";
import ProfileIMG from '../../img/dilegzersizlogo.png';
import Navbar from "../../components/Navbars/AuthNavbar.js";
import Footer from "../../components/Footers/Footer.js";
import SecureLS from "secure-ls";
import {Button, Table, notification} from 'antd';
import Api from "../../components/common/Api";
import SEO from "../../components/seo";

export default function Profile() {
    const [userInfos, setUserInfos] = useState()
    const [userExercises, setUserExercises] = useState()
    const [userId, setUserId] = useState()
    const [userRole, setUserRole] = useState()

    const loadData = () => {
        if (typeof localStorage !== 'undefined') {
            const ls = new SecureLS({encodingType: 'aes'});
            if(ls.get('secret_key').userRole === 'guest_customer'){
                navigate("/")
            }
            setUserId(ls.get('secret_key').userId)
            setUserRole(ls.get('secret_key').userRole)
            Api.getUserExercises({userId: ls.get('secret_key').userId})
                .then((response) => {
                    setUserExercises(response.data.user_exercises)
                })
                .catch((e) => console.log(e.response.request));
            Api.getUserInfos({userId: ls.get('secret_key').userId})
                .then((response) => {
                    setUserInfos(response.data.user_infos)
                })
                .catch((e) => console.log(e.response.request));
        }
    }

    useEffect(() => {
        loadData()
    }, [])
    
    const columns = [
        {
            title: 'Egzersiz Adı',
            dataIndex: 'exercise_name',
            key: 'exercise_name',
        },
        {
            title: 'Konu',
            dataIndex: 'subject_name',
            key: 'subject_name',
        },
        {
            title: 'Alınan Puan',
            dataIndex: 'exercise_result',
            key: 'exercise_result',
            sorter: (a, b) => a.exercise_result - b.exercise_result,
            sortDirections: ['descend', 'ascend'],
            render: (text, record) => (parseInt(record.is_finished) === 1 ? record.exercise_result : <span>Henüz Bitirmediniz!</span>)
        },
        {
            title: 'İncele',
            dataIndex: 'incele',
            key: 'incele',
            render: (text, record) => (<Link to={"/egzersiz/" + record.exercise_slug}>İncele</Link>) 
        },
    ];
    
    const dataTable = [];
    
    let toplamA2SertifikaEgzersizPuani = 0;
    let toplamA2YapilanSertifikaEgzersiz = 0;
    let toplamB2SertifikaEgzersizPuani = 0;
    let toplamB2YapilanSertifikaEgzersiz = 0;
    let toplamNormalEgzersizPuani = 0;
    let toplamYapilanNormalEgzersiz = 0;
    userExercises && userExercises.forEach((item) => {
        if(parseInt(item.is_finished) === 1){
            if(parseInt(item.is_exercise_important) === 1){
                if(item.certificate_level === 'A2'){
                    toplamA2SertifikaEgzersizPuani += parseInt(item.exercise_result);
                    toplamA2YapilanSertifikaEgzersiz++;
                }else{
                    toplamB2SertifikaEgzersizPuani += parseInt(item.exercise_result);
                    toplamB2YapilanSertifikaEgzersiz++;
                }
            }
            else{
                toplamNormalEgzersizPuani += parseInt(item.exercise_result);
                toplamYapilanNormalEgzersiz++;
            }
        }
        let addedData = 
            {
                key: item.exercise_id,
                exercise_name: item.exercise_name,
                exercise_slug: item.exercise_slug,
                subject_name: item.subject_name,
                is_finished: item.is_finished,
                is_started: item.is_started,
                is_making: item.is_making,
                exercise_result: item.exercise_result
            }
        dataTable.push(addedData)
    })
    return (
        <>
            <SEO 
                title="Profiliniz" 
                description="Egzersiz yaparken gelişiminizi görebileceğeniz bir sayfa." 
            />
            <Navbar transparent />
            <main className="profile-page">
                <section className="relative block h-500-px">
                    <div
                        className="absolute top-0 w-full h-full bg-center bg-cover"
                        style={{
                            backgroundImage:
                                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
                        }}
                    >
                        <span
                            id="blackOverlay"
                            className="w-full h-full absolute opacity-50 bg-black"
                        >
                        </span>
                    </div>
                    <div
                        className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden h-70-px"
                        style={{ transform: "translateZ(0)" }}
                    >
                        <svg
                            className="absolute bottom-0 overflow-hidden"
                            xmlns="http://www.w3.org/2000/svg"
                            preserveAspectRatio="none"
                            version="1.1"
                            viewBox="0 0 2560 100"
                            x="0"
                            y="0"
                        >
                            <polygon
                                className="text-gray-300 fill-current"
                                points="2560 0 2560 100 0 100"
                            >
                                
                            </polygon>
                        </svg>
                    </div>
                </section>
                <section className="relative py-16 bg-gray-300">
                    <div className="container mx-auto px-4">
                        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
                            <div className="px-6">
                                <div className="flex flex-wrap justify-center">
                                    <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                                        <div className="relative">
                                            <img
                                                alt="..."
                                                src={ProfileIMG}
                                                className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 lg:order-3 lg:text-right lg:self-center">
                                        <div className="py-6 px-3 mt-32 sm:mt-0">
                                            <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800 mb-2">
                                                {userInfos && userInfos.name}
                                            </h3>
                                            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold">
                                                <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>{" "}
                                                {userInfos && userInfos.email}
                                                <a
                                                    href="#"
                                                    className={
                                                        "text-sm py-2 px-4 font-normal block w-full whitespace-no-wrap bg-transparent text-gray-800"
                                                    }
                                                    onClick={(e) =>{
                                                        e.preventDefault()
                                                        if(typeof localStorage !== "undefined"){
                                                            const ls = new SecureLS({encodingType: 'aes'});
                                                            ls.removeAll()
                                                        }
                                                        window.location.reload()
                                                    }
                                                    }
                                                >
                                                    Çıkış Yap
                                                </a>
                                            </div>
                                            <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold">
                                                {typeof localStorage !== 'undefined' && userInfos && userInfos.role === 'free_customer' 
                                                    ? <Button onClick={() => {
                                                        Api.getThinkificUser({
                                                            email: userInfos && userInfos.email
                                                        })
                                                            .then((response) => {
                                                                if(response.data.code === 1){
                                                                    const openOkeyNotificationWithIcon = type => {
                                                                        notification[type]({
                                                                            message: 'Başarılı!',
                                                                            description:
                                                                                'Başarıyla üyeliğiniz yükseltildi!',
                                                                        });
                                                                    };
                                                                    openOkeyNotificationWithIcon('success');
                                                                    const ls = new SecureLS({encodingType: 'aes'});
                                                                    ls.removeAll();
                                                                    navigate('/auth/login')
                                                                }
                                                                else{
                                                                    const openRejectNotificationWithIcon = type => {
                                                                        notification[type]({
                                                                            message: 'Başarısız!',
                                                                            description:
                                                                                'Maalesef, üyeliğiniz yükseltilemedi. Bir sorun olduğunu düşünüyorsanız yönetim ile iletişime geçiniz!',
                                                                        });
                                                                    };
                                                                    openRejectNotificationWithIcon('error');
                                                                }
                                                            })
                                                            .catch((e) => console.log(e));
                                                    }}>Ücretli Üyelik Kontrol Et!</Button>
                                                    : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full lg:w-4/12 px-4 lg:order-1">
                                        <div className="flex justify-center py-4 lg:pt-4 pt-8">
                                            {/*<div className="mr-3 p-3 text-center">*/}
                                            {/*<span className="text-xl font-bold block uppercase tracking-wide text-gray-700">*/}
                                            {/*    {userExercises && userExercises.length}*/}
                                            {/*</span>*/}
                                            {/*<span className="text-sm text-gray-500">Egzersiz Sayısı</span>*/}
                                            {/*</div>*/}
                                            <div style={{display: 'none'}} className="mr-3 p-3 text-center">
                                            <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                                                {isNaN((toplamA2SertifikaEgzersizPuani / toplamA2YapilanSertifikaEgzersiz).toFixed(2)) ? '"' : (toplamA2SertifikaEgzersizPuani / toplamA2YapilanSertifikaEgzersiz).toFixed(2)}
                                            </span>
                                            <span className="text-sm text-gray-500">Ortalama A2 Sertifika Puanı</span>
                                            </div>
                                            <div style={{display: 'none'}} className="mr-3 p-3 text-center">
                                            <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                                                {isNaN((toplamB2SertifikaEgzersizPuani / toplamB2YapilanSertifikaEgzersiz).toFixed(2)) ? '"' : (toplamB2SertifikaEgzersizPuani / toplamB2YapilanSertifikaEgzersiz).toFixed(2)}
                                            </span>
                                                <span className="text-sm text-gray-500">Ortalama B2 Sertifika Puanı</span>
                                            </div>
                                            <div className="mr-3 p-3 text-center">
                                            <span className="text-xl font-bold block uppercase tracking-wide text-gray-700">
                                                {(toplamNormalEgzersizPuani / toplamYapilanNormalEgzersiz).toFixed(2)}
                                            </span>
                                                <span className="text-sm text-gray-500">Ortalama Normal Egzersiz Puanı</span>
                                            </div>
                                            {/*<div className="lg:mr-3 p-3 text-center">*/}
                                            {/*<span className="text-sm font-bold block uppercase tracking-wide text-gray-700">*/}
                                            {/*  {userInfos && userInfos.created_at.split('T')[0]}*/}
                                            {/*</span>*/}
                                            {/*<span className="text-sm text-gray-500">Üyelik Tarihi</span>*/}
                                            {/*</div>*/}
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center">
                                    <Table 
                                        columns={columns} 
                                        dataSource={dataTable} 
                                        pagination={{ position: ['bottomCenter'] }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
            <Footer />
        </>
    );
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`
