import React from "react";
import { FaFacebookF, FaTwitter, FaYoutube, FaCameraRetro, FaPinterestP } from "react-icons/fa";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-gray-300 pt-8 pb-6">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center">
              <div className="text-sm text-gray-600 font-semibold py-1">
                © {new Date().getFullYear()}{" "} Tüm Hakları Saklıdır. 
                <br/>
                <a
                    href="https://dil.denizcirpan.com/"
                    style={{marginRight: '5px'}}
                    className="text-black hover:text-blue-600 text-sm font-semibold py-1"
                >Gizlilik Politikası
                </a>
                |
                <a
                    href="https://dil.denizcirpan.com/"
                    style={{marginLeft: '5px'}}
                    className="text-black hover:text-blue-600 text-sm font-semibold py-1"
                >Deniz Çırpan
                </a>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
